$body-background-color: $grey-dk-300;
$sidebar-color: $grey-dk-300;
$border-color: $grey-dk-200;
$body-text-color: $grey-lt-300;
$body-heading-color: $grey-lt-000;
$nav-child-link-color: $grey-dk-000;
$search-result-preview-color: $grey-dk-000;
$link-color: $blue-000;
$btn-primary-color: $blue-200;
$base-button-color: $grey-dk-250;
$search-background-color: $grey-dk-250;
$table-background-color: $grey-dk-250;
$feedback-color: darken($sidebar-color, 3%);

// The following highlight theme is more legible than that used for the light color scheme

// @import "./vendor/OneDarkJekyll/syntax-one-dark";
// $code-background-color: #282c34;

@import "./vendor/OneDarkJekyll/syntax-one-dark-vivid";

$code-background-color: #31343f;

// @import "./vendor/OneDarkJekyll/syntax-firewatch";
// $code-background-color: #282c34;

// @import "./vendor/OneDarkJekyll/syntax-firewatch-green";
// $code-background-color: #282c34;
