// 90% bottom border
.bb-90 {
    width: 90%;
    border-bottom: 1px solid;
}

.flex-auto {
    flex: auto
}

.main-content li > ul {
    margin-top: 0;
    padding-top: 0;
}

// remove top margin jekyll+JTD inserts with mermaid
// uses: <pre><code class="language-mermaid">...</code></pre>
.main-content pre:has(> code.language-mermaid) {
    margin-top: 0;
}

// floating div around toc
div.toc {
    width: 35%;
    float: right;
    border: 1px solid white;
    border-radius: 5px;
    margin: 0 0 0.7rem 0.7rem;
    padding: 0.25rem;
    line-height: 1.4;

    // allejo table of contents
    details > ul > li > a {
        border-bottom: 1px solid white;
        padding-right: 1.5rem;
        width: 70%;
        display: inline-block;
        text-decoration: none;
        overflow: unset;
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;

        li {
            padding-left: 5px;
        }

        li::before {
            position: unset;
            margin-left: unset;
            content: unset;
        }
    }
}

header > dl {
    border-left: 1px solid #44434d;
    border-bottom: 1px solid #44434d;
    padding-left: 0.5em;
}

table.compact th, table.compact td {
    padding: 0 !important;
    min-width: auto;
    text-align: center;
    //border-collapse: collapse;
}

table.compact .table-wrapper {
    margin: 0;
}
